body {
  background-color: #f5f5f5;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Maison Neue";
  src: local("Maison Neue"), url(./assets/fonts/MaisonNeue-Book.ttf) format("truetype");
}

@font-face {
  font-family: "Maison Neue Light";
  src: local("Maison Neue Light"),
  url(./assets/fonts/MaisonNeue-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Maison Neue Bold";
  src: local("Maison Neue Bold"),
  url(./assets/fonts/MaisonNeue-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Maison Neue Medium";
  src: local("Maison Neue Medium"),
  url(./assets/fonts/MaisonNeue-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v83/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
  format("woff2");
}

.material-icons {
  font-family: "Material Icons", serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
